<template>
    <div>
        <div v-if="mainPageHeaders.length > 0">
            <div v-for="header in mainPageHeaders" :key="header.id">
                <div>
                    <h1>H1: {{ header.header }}</h1>
                    <h2>H2: {{ header.subheader1 }}</h2>
                    <h3> H3: {{ header.subheader2 }}</h3>
                    <v-btn @click="editMainPageHeader(header)">Edit</v-btn>
                </div>
                <v-divider />
            </div>
        </div>
        <v-card class="mx-auto pa-5" max-width="500">
            <v-card-title class="text-h5">Add New Main Page Header</v-card-title>
            <v-card-text>
                <v-form @submit.prevent="addMainPageHeader">
                    <v-text-field v-model="newHeader" placeholder="Header" />
                    <v-text-field v-model="newSubheader1" placeholder="Subheader1" />
                    <v-text-field v-model="newSubheader2" placeholder="Subheader2" />
                    <v-btn v-if="showAddMainPageHeaderButton" type="submit">Add</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
        <v-dialog v-model="editMainPageHeaderDialog" max-width="90%">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Main Page Header</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="editHeader" />
                    <v-text-field v-model="editSubheader1" />
                    <v-text-field v-model="editSubheader2" />
                    <v-btn color="green" @click="saveMainPageHeader">Save</v-btn>
                    <v-btn color="red" @click="editMainPageHeaderDialog = false">Cancel</v-btn>
                    <v-btn color="red" @click="deleteMainPageHeader">Delete</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import fire from "@/main";

export default {
    data() {
        return {
            mainPageHeaders: [],
            addingMainPageHeader: false,
            newHeader: "",
            newSubheader1: "",
            newSubheader2: "",
            editID: "",
            editHeader: "",
            editSubheader1: "",
            editSubheader2: "",
            editMainPageHeaderDialog: false,
        };
    },
    mounted() {
        console.log("MainPageHeaders.vue mounted");
        this.getMainPageHeadersFromFirebase();
    },
    computed: {
        showAddMainPageHeaderButton() {
            let ret = false;
            if (this.newHeader && this.newSubheader1 && this.newSubheader2) {
                ret = true;
            }
            if (this.addingMainPageHeader) {
                ret = false;
            }
            return ret;
        },
    },
    methods: {
        getMainPageHeadersFromFirebase() {
            let typesRef = fire.database().ref("admin/crm/mainPageHeaders");
            typesRef.on("value", (snapshot) => {
                let items = snapshot.val();
                let newState = [];
                for (let item in items) {
                    newState.push({
                        id: item,
                        // editMode: false,
                        ...items[item],
                    });
                }
                this.mainPageHeaders = newState;
            });
        },
        async addMainPageHeader() {
            if (!this.newHeader) {
                return;
            }
            this.addingMainPageHeader = true;
            // Add new mainPageHeader to the database
            let newMainPageHeader = {
                header: this.newHeader,
                subheader1: this.newSubheader1,
                subheader2: this.newSubheader2,
            };
            // Code to add newMainPageHeader to the database
            await fire.database().ref("admin/crm/mainPageHeaders").push(newMainPageHeader);
            // After adding, clear the input fields
            this.newHeader = "";
            this.newSubheader1 = "";
            this.newSubheader2 = "";
            this.addingMainPageHeader = false;
        },
        async saveMainPageHeader() {
            this.editMainPageHeaderDialog = false;
            if (!this.editID) {
                return;
            }
            if (!this.editHeader) {
                return;
            }
            await fire.database().ref("admin/crm/mainPageHeaders").child(this.editID).update({
                header: this.editHeader,
                subheader1: this.editSubheader1,
                subheader2: this.editSubheader2,
            });
            this.editID = "";
            this.editHeader = "";
            this.editSubheader1 = "";
            this.editSubheader2 = "";
        },
        async editMainPageHeader(header) {
            this.editID = header.id;
            this.editHeader = header.header;
            this.editSubheader1 = header.subheader1;
            this.editSubheader2 = header.subheader2;
            this.editMainPageHeaderDialog = true;
        },
        async deleteMainPageHeader() {
            this.editMainPageHeaderDialog = false;
            if (!this.editID) {
                return;
            }
            await fire.database().ref("admin/crm/mainPageHeaders").child(this.editID).remove();
            this.editID = "";
            this.editHeader = "";
            this.editSubheader1 = "";
            this.editSubheader2 = "";
        },
    },
};
</script>